<template>
    <div>
        <div class="item_info_input">
            <input type="text" v-model="form.name" placeholder="请输入您的姓名">
        </div>
        <div class="item_info_input">
            <input type="text" v-model="form.phone" placeholder="请输入您的联系方式">
        </div>
        <div class="item_info_input">
            <input type="text" v-model="form.desrcption" placeholder="请输入您的公司名称">
        </div>
        <div class="item_info_input">
            <button @click="channel">提交信息</button>
        </div>
        <!-- 注册成功 -->
        <!-- <div class="success_dialog_box" v-if="show">
            <div class="success_dialog_content" >
                <div class="success_box">
                    <div class="success_dialog_icon"></div>
                    <div class="success_dialog_title">恭喜您，申请试用成功<br>请保持电话通畅，我们将尽快与您联系</div>
                </div>
                <div class="success_dialog_btn" @click="bindClose">我知道了</div>
            </div>
        </div> -->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    name: '',
                    phone: '',
                    desrcption: ''
                },
                show: false
            }
        },
        mounted() {},
        methods: {
            channel() {
                let that = this;
                let href = window.location.search
                href = href.substring(1, href.length);
                const arr = href.split("&");
                const obj = new Object();
                let from_url = window.location.origin
                if (href) {
                    for(let i = 0; i < arr.length; i++) {
                        const tmp_arr = arr[i].split("=");
                        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
                    }
                }
                if (JSON.stringify(obj) != '{}') {
                    from_url = from_url + '/src_id=' + obj.src_id + '&myq=' + obj.myq + '&utm_source=' + obj.utm_source + '&utm_medium=' + obj.utm_medium + '&utm_campaign=' + obj.utm_campaign + '&utm_term=' + obj.utm_term + '&utm_content=' + obj.utm_content
                }
                const params = { ...this.form , ...obj }
                if (!this.checkPhone(this.form.phone)) {
                    return;
                }
                params.from_url = from_url
                axios({
                    method: 'post',
                    url: '/experience',
                    data: params
                })
                    .then(function (response) {
                        console.log('成功')
                        if (response.status == 201) {
                            $('.success_dialog_box').css({
                                'display': 'block'
                            })
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status == 400 || error.response.status == 500) {
                            swal({
                                title: error.response.data.message,
                                text: "",
                                icon: "error"
                            });
                        }
                    });
            },
            checkPhone(phone) {
                if (!(/^1[3-9]\d{9}$/.test(phone))) {
                    swal({
                        title: "手机号码有误，请重填",
                        text: "",
                        icon: "error"
                    });
                    return false;
                }
                return true;
            },
            bindClose() {
                this.show = false
            }
        }
    }
</script>
