<template>
    <div>
        <a href="javascript: void(0)" class="startVideo">
            <img src="../../images/videoPlay.png" alt="">
            <span @click="toggleVideoView(videoData.length)">免费学习</span>
        </a>
        <div class="videoPlay_View" v-if="videoData.length > 0" :style="{display: (isvideoShow ? 'block' : '')}">
            <div class="videoContent">
                <div class="video_titleClose">
                    <h2>千汇团小程序{{videoName ? videoName : videoData[0].title}}</h2>
                    <img src="../../images/close.png" @click="toggleVideoView"/>
                </div>
                <div class="video_playMenulist">
                    <div class="videoPlay">
                        <video width="100%" height="100%" :poster="videoPoster ? videoPoster : videoData[0].poster" :src="videoUrl ? videoUrl : videoData[0].url" controls="controls"></video>
                    </div>
                    <div class="videoMenulist">
                        <ul>
                            <li v-for="(item,index) in videoData" @click="rePlayVideo(item.url,item.title,item.poster)">
                                <span>{{item.time}}</span>
                                <span>{{item.title}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        props: ['videos'],  //此处放接收activeId字段和menuList字段
        data() {
            return {
                isvideoShow: false,   //视频播放模块显示
                videoUrl: '',         //当前视频的链接地址
                videoName: '',        //当前播放视频的标题
                videoPoster: '',      //当前视频播放前加载图
                videoData:this.videos
            }
        },
        created(){

        },
        mounted(){

        },
        methods: {
            toggleVideoView(videoData_length){  //视频播放模块显示切换
                console.log('videoData_length',videoData_length);
                if(videoData_length === 0){
                    swal({
                        title: "视频待更新中",
                        text: "敬请期待",
                        icon: "error"
                    });
                }else{
                    this.isvideoShow = !this.isvideoShow;
                    this.videoUrl = '';
                    this.videoName = '';
                    this.videoPoster = '';
                }
            },
            rePlayVideo(videoUrl,videoName,videoPoster){   //播放菜单切换视频播放
                this.videoUrl = videoUrl;
                this.videoName = videoName;
                this.videoPoster = videoPoster;
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
