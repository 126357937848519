var render, staticRenderFns
import script from "./Questions.vue?vue&type=script&lang=js&"
export * from "./Questions.vue?vue&type=script&lang=js&"
import style0 from "./Questions.vue?vue&type=style&index=0&id=0bbf2abc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bbf2abc",
  null
  
)

component.options.__file = "Questions.vue"
export default component.exports