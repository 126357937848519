<script>
    export default {
        props: ['category', 'category_id'],  //此处放接收activeId字段和menuList字段
        data() {
            return {
                questionData: {
                    activeId: this.category_id,
                    menuList: this.category
                }
            }
        },
        created(){

        },
        mounted(){
            console.log('this.questionData.menuList', this.questionData.menuList);
            $('.asideMenu >li').on('click', function () {
                var index = $(this).index();
                console.log('index', index);
                $(this).find('ul').css('display', 'block');
                $(this).siblings('li').find('ul').css('display', 'none');
                $(this).find('.oneMenu').children('img').attr('src','../../images/top.png');
                $(this).siblings('li').find('.oneMenu').children('img').attr('src','../../images/down.png');
            });
            $('.questionNav').on('click', function () {
                $('.asideMenu').find('a').removeClass('navActive');
                $(this).children('a').addClass('navActive');

            })
        },
        methods: {

        }
    }



</script>


<style scoped lang="scss">

</style>
