<template>
<div class="search">
    <div class="h3">现在选择千汇团，让你的每一位客户更加满意</div>
    <div>
        <div style="display:flex;">
            <input name="tel" type="tel" v-model="phone" placeholder="输入手机号稍后我们会与您联系" required>
            <button @click="experience">立即体验</button>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        props: ['paramers'],
        data() {
            return {
                phone: '',
                src_id: '',
                myq: '',
                can: false
            }
        },
        mounted() {
             let data = JSON.parse(this.paramers);

             if (data['src_id']) {
                 this.src_id = data['src_id'];
             }

             if (data['myq']) {
                 this.myq = data['myq'];
             }
        },
        methods: {
            experience() {
                let that = this;
                if (!this.checkPhone(this.phone)) {
                    return;
                }
                const params = {
                    phone: this.phone,
                    src_id: this.src_id,
                    myq: this.myq
                }
                params.from_url = window.location.origin + from_url + '/src_id=' + this.src_id + '&myq=' + this.myq
                axios({
                    method: 'post',
                    url: '/channel',
                    data: params
                })
                .then(function (response) {
                    console.log(response.data.message);
                    if (response.status == 201) {
                        swal({
                            title: response.data.message,
                            text: "",
                            icon: "success"
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error.response);
                    if (error.response.status == 400) {
                        swal({
                            title: error.response.data.message,
                            text: "",
                            icon: "error"
                        });
                    }
                });
            },
            checkPhone(phone){
                if(!(/^1[34578]\d{9}$/.test(phone))){
                    swal({
                        title: '手机号码有误，请重填',
                        text: "",
                        icon: "error"
                    });
                    return false;
                }
                return true;
            }
        },
    }
</script>
