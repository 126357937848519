/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('qht-experience', require('./components/Experience.vue').default);
Vue.component('qht-contact', require('./components/Contact.vue').default);
Vue.component('qht-questions', require('./components/Questions.vue').default);
Vue.component('qht-video', require('./components/Video.vue').default);
Vue.component('qht-free', require('./components/Free.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import Swiper from 'swiper'
import swal from 'sweetalert';   //提示框

const app = new Vue({
    el: '#app',
    data: () => ({
        isShow_qqContacts: false,
        step: 0,
        goodSwiper_index: 0,
        priceFrom: {
            name: '',
            phone: ''
        },
        registerError: false
    }),
    mounted() {
        var swiper = new Swiper('.swiper-container', {
            // spaceBetween: 30,
            // centeredSlides: true,
            // autoplay: {
            //     delay: 4000,
            //     disableOnInteraction: false,
            // },
            pagination: {
                el: '.home_pagination',
                clickable: true,
            },
            loop: true,
            navigation: {
                nextEl: '.banner_swiper-button-next',
                prevEl: '.banner_swiper-button-prev',
            }
        });
        $('.swiper-container>.swiper-wrapper> .swiper-slide').mouseenter(function () {
            swiper.autoplay.stop();
        });
        $('.swiper-container>.swiper-wrapper> .swiper-slide').mouseleave(function () {
            swiper.autoplay.start();
        });


        // 首页社区团购的特点轮播
        var swiper2 = new Swiper('.swiper-container2', {
            centeredSlides: true,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },
            loop: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            on: {
                slideChangeTransitionEnd: function () {
                    var index = this.activeIndex;
                    var index2 = index + 1;
                    this.goodSwiper_index = index;
                    for (let i = 0, len = 6; i < len; i++) {
                        let _index = i + 1;
                        $(".goodsNav li").eq(i).children('.goodsli_img1').attr('src', '../../images/homeGoods_img' + _index + '.png');
                        $(".goodsNav li").eq(i).children('span').removeClass('span_active');
                        $(".goodsNav li").eq(i).children('.goodsli_img2').css('opacity', 0);
                    }
                    $(".goodsNav li").eq(index).children('.goodsli_img1').attr('src', '../../images/homeGoods_activeimg' + index2 + '.png');
                    $(".goodsNav li").eq(index).children('span').addClass('span_active');
                    $(".goodsNav li").eq(index).children('.goodsli_img2').css('opacity', 1);
                }
            }
        });

        $('.good .goodsNav li').on('mouseenter',function () {
            var index = Number($(this).index());
            var index2 = index + 1;
            swiper2.slideTo(index, 300, false);
            for (let i = 0, len = 6; i < len; i++) {
                let _index = i + 1;
                $(".goodsNav li").eq(i).children('.goodsli_img1').attr('src', '../../images/homeGoods_img' + _index + '.png');
                $(".goodsNav li").eq(i).children('span').removeClass('span_active');
                $(".goodsNav li").eq(i).children('.goodsli_img2').css('opacity', 0);
            }
            $(".goodsNav li").eq(index).children('.goodsli_img1').attr('src', '../../images/homeGoods_activeimg' + index2 + '.png');
            $(".goodsNav li").eq(index).children('span').addClass('span_active');
            $(".goodsNav li").eq(index).children('.goodsli_img2').css('opacity', 1);
        });
        new Swiper('.marketing_swiper', {
            // autoplay: {
            //     delay: 10000,
            //     disableOnInteraction: false,
            // },
            loop: true,
            navigation: {
                nextEl: '.marketing_swiper-button-next',
                prevEl: '.marketing_swiper-button-prev',
            }
        });
        new Swiper('.adv03_swiper', {
            slidesPerView: 4,
            spaceBetween : 58,
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });
        var joinSwiper = new Swiper('.join_swiper', {
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                clickable :true,
                type: 'custom',
                renderCustom: function (swiper, current, total) {
                    // '返利支持', '技术支持',
                    var currentTitle = ['销售支持', '培训支持', '资源支持', '物料支持', '产品支持',  '客服支持', 'crm支持', '技术保障']
                    var customPaginationHtml = "";
                    for (var i = 0; i < currentTitle.length; i++) {
                        if(i == (current - 1)) {
                            customPaginationHtml += "<div classe='swiper-pagination-customs swiper-pagination-customs-active'><img src='../images/product_join_icon0"+ i +"_active.png'/><p>"+ currentTitle[i] +"</p></div>";
                        } else {
                            customPaginationHtml += "<div classe='swiper-pagination-customs '><img src='../images/product_join_icon0"+ i +".png'/><p>"+ currentTitle[i] +"</p></div>";
                        }
                    }
                    return customPaginationHtml;
                }
            }
        });
        $('.join_pagination').on('click','div',function(){
            var index = $(this).index() + 1;
            joinSwiper.slideTo(index, 1000, false)
        });
        var patternSwiper = new Swiper('.pattern_swiper', {
            loop: true,
            pagination: {
                el: '.pattern_pagination',
                type: 'custom',
                renderCustom: function (swiper, current, total) {
                    var customPaginationHtml = "";
                    for(var i = 0; i < total; i++) {
                        if(i == (current - 1)) {
                            customPaginationHtml += "<span class='swiper-pagination-customs swiper-pagination-customs-active'>"+ '0' + current + "</span>" + "<span class='swiper-pagination-total'>"+ ' / 0' + total +"</span>";
                            $('.slide_upright_item')[i].className = 'slide_upright_item active'
                        } else {
                            $('.slide_upright_item')[i].className = 'slide_upright_item'
                        }
                    }
                    customPaginationHtml += "<p class='swiper-pagination-select'></p>"
                    return customPaginationHtml;
                }
            },
            navigation: {
                nextEl: '.pattern_next',
                prevEl: '.pattern_prev',
            }
        });
        $('.slide_upright_item').on('mouseenter', function() {
            var index = $(this).data('index')
            patternSwiper.slideTo(index, 1000, false)
        })
        $(document).scroll(function() {
            var scroH = $(document).scrollTop();  //滚动高度
            if (scroH >= $('#banner_home').height() - 70) {
                $('#pc_header').addClass('bgf')
                $('#pc_logo').attr('src', 'https://img.qianhuituan.cn/web/images/bg_logo.png');
            } else {
                $('#pc_header').removeClass('bgf')
                $('#pc_logo').attr('src', 'https://img.qianhuituan.cn/web/images/qianhuituan_logo2.png');
            }
        })
        var programSwiper = new Swiper('.swiper-program', {
            autoplay: {
                delay: 10000,
                disableOnInteraction: false,
            },
            loop: true,
            navigation: {
                nextEl: '.program_swiper-button-next',
                prevEl: '.program_swiper-button-prev',
            },
            on:{
                slideChangeTransitionEnd: function(){
                    var index = this.activeIndex - 1
                    if (index ==4) {
                        index = 0
                    }
                    $('.header_nav_item').eq(index).addClass('active').children('.nav_item_line').css('display','block')
                    $('.header_nav_item').eq(index).siblings().removeClass('active').children('.nav_item_line').css('display','none')
                }
            }
        });
        $('.header_nav_item').on('click',function(){
            var index = $(this).index() + 1;
            programSwiper.slideTo(index, 1000, false)
            $(this).addClass('active').children('.nav_item_line').css('display','block')
            $(this).siblings().removeClass('active').children('.nav_item_line').css('display', 'none')
        });
        new Swiper('.swiper_container_shop', {
            autoplay: {
                delay: 10000
            },
            loop: true,
            pagination: {
                el: '.shop_pagination'
            },
            navigation: {
                nextEl: '.shop_swiper-button-next',
                prevEl: '.shop_swiper-button-prev',
            }
        });
        $('.scene_item_top').on('mouseenter', function(e) {
            var index = $(this).index();
            $(this).addClass('active').siblings().removeClass('active')
            $('.scene_item_bottom').eq(index).css('display', 'flex').siblings().css('display', 'none')
        })
        var groupSwiper = new Swiper('.swiper_container_group', {
            autoplay: {
                delay: 15000
            },
            loop: true,
            navigation: {
                nextEl: '.group_swiper-button-next',
                prevEl: '.group_swiper-button-prev',
            },
            on: {
                slideChangeTransitionEnd: function () {
                    var index = this.activeIndex - 1;
                    if (index ==5) {
                        index = 0
                    }
                    $('.new_home_tag').eq(index).addClass('active').siblings().removeClass('active')
                }
            }
        });
        $('.new_home_tag').on('mouseenter',function(){
            var index = $(this).index() + 1;
            groupSwiper.slideTo(index, 1000, false)
            $(this).addClass('active').siblings().removeClass('active')
        });
        var shopSlove = new Swiper('.chain_shop_solve_swiper_container', {
            autoplay: {
                delay: 15000
            },
            loop: true,
            on: {
                slideChangeTransitionEnd: function () {
                    var index = this.activeIndex - 1;
                    if (index ==4) {
                        index = 0
                    }
                    $('.slove_header').eq(index).addClass('active').siblings().removeClass('active')
                }
            }
        });
        $('.slove_header').on('click',function(){
            var index = $(this).index() + 1;
            shopSlove.slideTo(index, 1000, false)
            $(this).addClass('active').siblings().removeClass('active')
        });
        var solutionSwipwe = new Swiper('.swiper_container_solution', {
            autoplay: {
                delay: 15000
            },
            loop: true,
            on: {
                slideChangeTransitionEnd: function () {
                    var index = this.activeIndex - 1;
                    if (index ==4) {
                        index = 0
                    }
                    $('.solution_header').eq(index).addClass('active').siblings().removeClass('active')
                }
            }
        });
        $('.solution_header').on('mouseenter',function(){
            var index = $(this).index() + 1;
            solutionSwipwe.slideTo(index, 1000, false)
            $(this).addClass('active').siblings().removeClass('active')
        });
        // 弹出商户通
        $('.btn_right').on('click', function() {
            if ($('#LRMINIWIN').css('display') == 'block') {
                $('#LRMINIWIN').css({
                    'display': 'none'
                })
                setTimeout(() => {
                    $('#LRMINIWIN').css({
                        'display': 'block'
                    })
                }, 100);
            } else {
                $('#LRMINIWIN').css({
                    'display': 'block'
                })
                $('#LRMINIBar').css({
                    'display': 'none'
                })
            }
        })
        // 领取福利
        $('#homeBoon').on('click', function() {
            $('.home_left_servie_boon').addClass('boon_pacity')
            $('.home_left_servie').css({
                'display': 'none'
            })
            $('.home_left_servie_boon').css({
                'display': 'block'
            })
        })
        $("#getPrice").on('click', function() {
            $('.home_left_service_get_price_box').css({
                'display': 'block'
            })
            $('.home_left_service_get_price_container').addClass('showHeight').removeClass('hideHeight')
            $('.home_left_servie').css({
                'border': '1px solid #F03B44'
            })
        })
        $('.home_left_servie_boon').on('mouseleave', function() {
            $('.home_left_servie').css({
                'display': 'block'
            })
            $('.home_left_servie_boon').css({
                'display': 'none'
            })
        })
        $('.success_dialog_btn').on('click', function() {
            $('.success_dialog_box').css({
                'display': 'none'
            })
        })
    },
    methods: {
        goTop() {
            let distance = document.documentElement.scrollTop || document.body.scrollTop; //获得当前高度
            let step = distance / 50; //每步的距离
            (function jump() {
                if (distance > 0) {
                    distance -= step;
                    window.scrollTo(0, distance);
                    setTimeout(jump, 10)
                }
            })()
        },
        mouseover_qqContacts() {
            this.isShow_qqContacts = true
        },
        close_qqContacts() {
            this.isShow_qqContacts = !this.isShow_qqContacts
        },
        bindSubmit() {
            let href = window.location.search
            href = href.substring(1, href.length);
            const arr = href.split("&");
            const obj = new Object();
            let from_url = window.location.origin
            if (href) {
                for(let i = 0; i < arr.length; i++) {
                    const tmp_arr = arr[i].split("=");
                    obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
                }
            }
            const params = { ...this.priceFrom , ...obj }
            let that = this;
            if (!this.checkPhone(that.priceFrom.phone)) {
                return;
            }
            if (JSON.stringify(obj) != '{}') {
                from_url = from_url + '/src_id=' + obj.src_id + '&myq=' + obj.myq + '&utm_source=' + obj.utm_source + '&utm_medium=' + obj.utm_medium + '&utm_campaign=' + obj.utm_campaign + '&utm_term=' + obj.utm_term + '&utm_content=' + obj.utm_content
            }
            params.from_url = from_url
            axios({
                method: 'post',
                url: '/experience',
                data: params
            }).then(function (response) {
                if (response.status == 201) {
                    that.closeModule(response.data.message, '')
                    $('.success_dialog_box').css({
                        'display': 'block'
                    })
                }
            }).catch(function (error) {
                if (error.response.status == 400 || error.response.status == 500) {
                    that.closeModule(error.response.data.message, 'error')
                }
            });
        },
        checkPhone(phone) {
            if (!(/^1[3-9]\d{9}$/.test(phone))) {
                $('.get_price_error').css({
                    'display': 'block'
                })
                this.registerError = true
                return false;
            }
            $('.get_price_error').css({
                'display': 'none'
            })
            return true;
        },
        closeModule(message, icon) {
            const that = this
            $('.home_left_service_get_price_container').addClass('hideHeight').removeClass('showHeight')
            setTimeout(function() {
                $('.home_left_service_get_price_box').css({
                    'display': 'none'
                })
                $('.home_left_servie').css({
                    'border': '1px solid #fff'
                })
                that.priceFrom = {
                    name: '',
                    phone: ''
                }
                if (icon) {
                    swal({
                        title: message,
                        text: "",
                        icon: icon
                    });
                }
            }, 520)
        }
    }
});
